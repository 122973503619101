<template>
    <div class="content-box">
        <div class="content">
            <div>
                <h4 v-if="!flag" class="global_head_title">添加/编辑进件</h4>
                <!-- <div class="content_row">
                    <label class="info" for="">进件编号<span class="redS">*</span></label>
                    <div class="content_right">
                        <a-input type="number" v-model="osn" size="large" placeholder="请输入进件编号" />
                    </div>
                </div> -->
                <div class="content_row flex">
                    <label class="info" for="">关联客户 <span class="redS">*</span></label>
                    <div class="content_right">
                        <a-select size="large" ref="sel" v-model="client2" placeholder="请选择要添加的客户" :open="false"
                            @focus="chooseClient">
                            <!-- <a-select-option v-for="(item,index) in clientList" :key="index" :value="item.tmjcpb_corp__ids">
                                {{ item.tmjcpb_corp__name }}
                            </a-select-option> -->
                        </a-select>
                    </div>
                </div>
                <client :clientFlag="clientFlag" :clientMode="clientMode" @updateClientFlag="updateClientFlag"></client>
                <!-- <div class="content_row">
                    <label class="info" for="">商家名称<span class="redS">*</span></label>
                    <div class="content_right">
                        <a-input v-model="tmjcpb_corp__name" size="large" placeholder="请输入商家名称" />
                    </div>
                </div>
                <div class="content_row">
                    <label class="info" for="">联系人<span class="redS">*</span></label>
                    <div class="content_right">
                        <a-input v-model="tmjcpb_corps__zd1719102503" size="large" placeholder="请输入联系人" />
                    </div>
                </div>
                <div class="content_row">
                    <label class="info" for="">联系电话<span class="redS">*</span></label>
                    <div class="content_right">
                        <a-input type="number" v-model="tmjcpb_corps__zd1719102509" size="large" placeholder="请输入联系电话" />
                    </div>
                </div> -->
                <div class="content_row flex">
                    <label class="info" for="">进件类型<span class="redS">*</span></label>
                    <div class="content_right">
                        <a-checkbox-group v-model="type" name="checkboxgroup" :options="options" />
                        <!-- <a-radio-group name="radioGroup" v-model="type">
                            <a-radio :value="1">
                                外卖
                            </a-radio>
                            <a-radio :value="2">
                                小时达
                            </a-radio>
                        </a-radio-group> -->
                    </div>
                </div>
                <div class="content_row flex">
                    <label class="info" for="">营业执照名称<span class="redS">*</span></label>
                    <div class="content_right">
                        <a-input v-model="license_name" size="large" placeholder="请输入营业执照名称" />
                    </div>
                </div>
                <div class="content_row flex">
                    <label class="info" for="">营业执照<span class="redS">*</span></label>
                    <div class="content_right">
                        <label v-if="license" class="fileLabel" for="license"><img
                                :src="url + '/scrm/index/getFile?id=' + license"></label>
                        <label v-else class="fileLabel" for="license"><img src="../../../assets/imgs/add2.png"
                                alt=""></label>
                        <input type="file" id="license" value="" @change="imageUpload($event, 3)">
                    </div>
                </div>
                <div class="content_row flex">
                    <label class="info" for="">门头照<span class="redS">*</span></label>
                    <div class="content_right">
                        <label v-if="doorstep_photo" class="fileLabel" for="doorstep_photo"><img
                                :src="url + '/scrm/index/getFile?id=' + doorstep_photo"></label>
                        <label v-else class="fileLabel" for="doorstep_photo"><img
                                src="../../../assets/imgs/add2.png" alt=""></label>
                        <input type="file" id="doorstep_photo" value="" @change="imageUpload($event, 11)">
                    </div>
                </div>

                <div class="content_row flex">
                    <label class="info" for="">法人身份证<span class="redS">*</span></label>
                    <div class="content_right" style="width:40%;display: inline-block;">
                        <label v-if="idcard_front" class="fileLabel" for="idcard_front"><img
                                :src="url + '/scrm/index/getFile?id=' + idcard_front"></label>
                        <label v-else class="fileLabel" for="idcard_front"><img style="width: 98%;"
                                src="@/assets/imgs/wap/just.png" alt=""></label>
                        <input type="file" id="idcard_front" value="" @change="imageUpload($event, 1)">
                    </div>
                    <div class="content_right" style="width:40%;display: inline-block;">
                        <label v-if="idcard_back" class="fileLabel" for="idcard_back"><img
                                :src="url + '/scrm/index/getFile?id=' + idcard_back"></label>
                        <label v-else class="fileLabel" for="idcard_back"><img style="width: 98%;"
                                src="@/assets/imgs/wap/against.png" alt=""></label>
                        <input type="file" id="idcard_back" value="" @change="imageUpload($event, 2)">
                    </div>
                </div>
                <div class="content_row flex">
                    <label class="info" for="">法人手机号<span class="redS">*</span></label>
                    <div class="content_right">
                        <a-input type="number" v-model="legal_phone" size="large" placeholder="请输入手机号" />
                    </div>
                </div>
                <!-- <div class="content_row flex">
                    <label class="info" for="">法人身份证反面照片<span class="redS">*</span></label>
                </div> -->
            </div>
            <div v-if="type.indexOf(2) > -1">
                <div class="content_row flex">
                    <label class="info" for="">法人支付宝账号<span class="redS">*</span></label>
                    <div class="content_right">
                        <a-input v-model="legal_zfb" size="large" placeholder="请输入支付宝账号" />
                    </div>
                </div>
                <div class="content_row flex">
                    <label class="info" for="">食品经营许可证<span class="redS">*</span></label>
                    <div class="content_right">
                        <label v-if="food_license" class="fileLabel" for="food_license"><img
                                :src="url + '/scrm/index/getFile?id=' + food_license"></label>
                        <label v-else class="fileLabel" for="food_license"><img src="../../../assets/imgs/add2.png"
                                alt=""></label>
                        <input type="file" id="food_license" value="" @change="imageUpload($event, 4)">
                    </div>
                </div>
                <div class="content_row flex">
                    <label class="info" for="">室内实景照片<span class="redS">*</span></label>
                    <div class="content_right">
                        <label v-if="in_store_photo" class="fileLabel" for="in_store_photo"><img
                                :src="url + '/scrm/index/getFile?id=' + in_store_photo"></label>
                        <label v-else class="fileLabel" for="in_store_photo"><img
                                src="../../../assets/imgs/add2.png" alt=""></label>
                        <input type="file" id="in_store_photo" value="" @change="imageUpload($event, 10)">
                    </div>
                </div>
                <div class="content_row flex">
                    <label class="info" for="">抖音来客门店管理中截图<span class="redS">*</span></label>
                    <div class="content_right">
                        <label v-if="tiktok_screenshot" class="fileLabel" for="tiktok_screenshot"><img
                                :src="url + '/scrm/index/getFile?id=' + tiktok_screenshot"></label>
                        <label v-else class="fileLabel" for="tiktok_screenshot"><img src="@/assets/imgs/add2.png"
                                alt=""></label>
                        <input type="file" id="tiktok_screenshot" value="" @change="imageUpload($event, 5)">
                    </div>
                </div>

                <div class="content_row flex">
                    <label class="info" for="">商品名称<span class="redS">*</span></label>
                    <div class="content_right">
                        <a-input v-model="commodity_name" size="large" placeholder="请输入商品名称" />
                    </div>
                </div>
                <div class="content_row flex">
                    <label class="info" for="">单商品图片（两张）<span class="redS">*</span></label>
                    <div class="content_right">
                        <template v-for="(o, i) in commodity_photo">
                            <img :key="i" v-if="o" :src="url + '/scrm/index/getFile?id=' + o"
                                style="margin-bottom:10px">
                        </template>
                        <label v-if="commodity_photo.length < 2" class="fileLabel" for="commodity_photo"><img
                                src="@/assets/imgs/add2.png" alt=""></label>
                        <input v-if="commodity_photo.length < 2" type="file" id="commodity_photo" value=""
                            @change="imagesUpload($event, 2)">
                    </div>
                </div>
                <div class="content_row flex">
                    <label class="info" for="">商品价格<span class="redS">*</span></label>
                    <div class="content_right">
                        <a-input v-model="commodity_price" size="large" placeholder="请输入商品价格" />
                    </div>
                </div>
                <div class="content_row flex">
                    <label class="info" for="">银行卡卡号<span class="redS">*</span></label>
                    <div class="content_right">
                        <a-input v-model="bank_account" size="large" placeholder="请输入银行卡卡号" />
                    </div>
                </div>
                <div class="content_row flex">
                    <label class="info" for="">银行卡开户行<span class="redS">*</span></label>
                    <div class="content_right">
                        <a-input v-model="bank" size="large" placeholder="请输入开户行" />
                    </div>
                </div>
                <div class="content_row flex">
                    <label class="info" for="">开户预留手机号码<span class="redS">*</span></label>
                    <div class="content_right">
                        <a-input type="number" v-model="opening_phone" size="large" placeholder="请输入手机号" />
                    </div>
                </div>
                <div class="content_row flex">
                    <label class="info" for="">抖音poi<span class="redS">*</span></label>
                    <div class="content_right">
                        <a-input v-model="tiktok_poi" size="large" placeholder="请输入抖音poi" />
                    </div>
                </div>
                <div class="content_row flex">
                    <label class="info" for="">营业时间<span class="redS">*</span></label>
                    <div class="content_right">
                        <a-input v-model="business_hours" size="large" placeholder="请输入营业时间" />
                    </div>
                </div>
            </div>
            <div v-if="type.indexOf(1) > -1">
                <div class="content_row flex">
                    <label class="info" for="">法人身份证号码<span class="redS">*</span></label>
                    <div class="content_right">
                        <a-input type="number" v-model="idcard_code" size="large" placeholder="请输入法人身份证号码" />
                    </div>
                </div>
                <div class="content_row">
                    <label class="info" for="">法人名称<span class="redS">*</span></label>
                    <div class="content_right">
                        <a-input v-model="idcard_name" size="large" placeholder="请输入法人名称" />
                    </div>
                </div>
                <div class="content_row flex">
                    <label class="info" for="">证件生效日期<span class="redS">*</span></label>
                    <div class="content_right">
                        <a-date-picker v-model="idcard_effective_date"  :value-format="'YYYY-MM-DD'"/>
                    </div>
                </div>
                <div class="content_row flex">
                    <label class="info" for="">证件失效日期<span class="redS">*</span></label>
                    <div class="content_right">
                        <a-date-picker v-model="idcard_expiring_date"  :value-format="'YYYY-MM-DD'"/>
                    </div>
                </div>
                <div class="content_row flex">
                    <label class="info" for="">法人身份证地址<span class="redS">*</span></label>
                    <div class="content_right">
                        <a-input v-model="idcard_address" size="large" placeholder="请输入法人身份证地址" />
                    </div>
                </div>
                <div class="content_row flex">
                    <label class="info" for="">商户签约名<span class="redS">*</span></label>
                    <div class="content_right">
                        <a-input v-model="signing_name" size="large" placeholder="请输入商户签约名" />
                    </div>
                </div>
                <div class="content_row flex">
                    <label class="info" for="">商户签约类型<span class="redS">*</span></label>
                    <div class="content_right">
                        <a-select ref="select" v-model="signing_type" placeholder="请选择商户签约类型">
                            <a-select-option :value="1">个体工商户</a-select-option>
                            <a-select-option :value="2">企业</a-select-option>
                        </a-select>
                    </div>
                </div>
                <div class="content_row flex">
                    <label class="info" for="">统一社会信用代码<span class="redS">*</span></label>
                    <div class="content_right">
                        <a-input v-model="unified_credit_code" size="large" placeholder="请输入统一社会信用代码" />
                    </div>
                </div>
                <div class="content_row flex">
                    <label class="info" for="">经营范围<span class="redS">*</span></label>
                    <div class="content_right">
                        <a-input v-model="business_scope" size="large" placeholder="请输入经营范围" />
                    </div>
                </div>
                <div class="content_row flex">
                    <label class="info" for="">商户简称<span class="redS">*</span></label>
                    <div class="content_right">
                        <a-input v-model="merchant_abbreviation" size="large" placeholder="请输入商户简称" />
                    </div>
                </div>
                <div class="content_row flex">
                    <label class="info" for="">所属平台<span class="redS">*</span></label>
                    <div class="content_right">
                        <a-select ref="select" v-model="platform_belongs" placeholder="请选择所属平台">
                            <a-select-option :value="1">抖音</a-select-option>
                            <a-select-option :value="2">美团</a-select-option>
                            <a-select-option :value="3">饿了么</a-select-option>
                        </a-select>
                    </div>
                </div>
                <div class="content_row flex">
                    <label class="info" for="">资质照片<span class="redS">*</span></label>
                    <div class="content_right">
                        <label v-if="qualification_photos" class="fileLabel" for="qualification_photos"><img
                                :src="url + '/scrm/index/getFile?id=' + qualification_photos"></label>
                        <label v-else class="fileLabel" for="qualification_photos"><img
                                src="../../../assets/imgs/add2.png" alt=""></label>
                        <input type="file" id="qualification_photos" value="" @change="imageUpload($event, 7)">
                    </div>
                </div>
                <div class="content_row flex">
                    <label class="info" for="">分账证明合同<span class="redS">*</span></label>
                    <div class="content_right">
                        <a :href="url + '/scrm/index/Download?id=' + orderField.value" v-if="orderField.value">{{
            orderField.value2
        }}</a>
                        <label class="fileLabel upFile" for="annex"><a-icon type="upload" /> 上传 </label>
                        <input type="file" id="annex" value="" @change="annexUpload($event)">
                    </div>
                </div>
                <div class="content_row flex">
                    <label class="info" for="">分佣比例<span class="redS">*</span></label>
                    <div class="content_right">
                        <a-input v-model="commission_sharing_ratio" size="large" placeholder="请输入分佣比例" />
                    </div>
                </div>
                <div class="content_row flex">
                    <label class="info" for="">平台服务费<span class="redS">*</span></label>
                    <div class="content_right">
                        <a-select ref="select" v-model="platform_service_fee" placeholder="请选择平台服务费">
                            <a-select-option :value="1">共同承担</a-select-option>
                            <a-select-option :value="2">服务商</a-select-option>
                            <a-select-option :value="3">商户</a-select-option>
                        </a-select>
                    </div>
                </div>
                <div class="content_row flex">
                    <label class="info" for="">实际经营地址<span class="redS">*</span></label>
                    <div class="content_right">
                        <a-input v-model="business_address" size="large" placeholder="请输入经营地址" />
                    </div>
                </div>
                <a-divider>商户开户信息</a-divider>
                <div class="content_row flex">
                    <label class="info" for="">开户银行账户类型<span class="redS">*</span></label>
                    <div class="content_right">
                        <a-select ref="select" v-model="bank_account_type" placeholder="请选择银行账户类型">
                            <a-select-option :value="1">对公</a-select-option>
                            <a-select-option :value="2">对私</a-select-option>
                        </a-select>
                    </div>
                </div>
                <div class="content_row flex">
                    <label class="info" for="">开户许可证照片<span class="redS">*</span></label>
                    <div class="content_right">
                        <label v-if="account_opening_license_photo" class="fileLabel"
                            for="account_opening_license_photo"><img
                                :src="url + '/scrm/index/getFile?id=' + account_opening_license_photo"></label>
                        <label v-else class="fileLabel" for="account_opening_license_photo"><img
                                src="../../../assets/imgs/add2.png" alt=""></label>
                        <input type="file" id="account_opening_license_photo" value="" @change="imageUpload($event, 8)">
                    </div>
                </div>
                <div class="content_row flex">
                    <label class="info" for="">银行卡号<span class="redS">*</span></label>
                    <div class="content_right">
                        <a-input v-model="bank_card_number" size="large" placeholder="请输入账户银行" />
                    </div>
                </div>
                <div class="content_row flex">
                    <label class="info" for="">开户总行<span class="redS">*</span></label>
                    <div class="content_right">
                        <a-input v-model="opening_bank" size="large" placeholder="请输入开户总行" />
                    </div>
                </div>
                <div class="content_row flex">
                    <label class="info" for="">开户支行<span class="redS">*</span></label>
                    <div class="content_right">
                        <a-input v-model="opening_branch" size="large" placeholder="请输入开户支行" />
                    </div>
                </div>
                <a-divider>商户收款账户信息</a-divider>
                <div class="content_row flex">
                    <label class="info" for="">开户银行账户类型<span class="redS">*</span></label>
                    <div class="content_right">
                        <a-select ref="select" v-model="collection_bank_account_type" placeholder="请选择银行账户类型">
                            <a-select-option :value="1">对公</a-select-option>
                            <a-select-option :value="2">对私</a-select-option>
                        </a-select>
                    </div>
                </div>
                <div class="content_row flex">
                    <label class="info" for="">收款开户许可证照片<span class="redS">*</span></label>
                    <div class="content_right">
                        <label v-if="collection_account_opening_license_photo" class="fileLabel"
                            for="collection_account_opening_license_photo"><img
                                :src="url + '/scrm/index/getFile?id=' + collection_account_opening_license_photo"></label>
                        <label v-else class="fileLabel" for="collection_account_opening_license_photo"><img
                                src="../../../assets/imgs/add2.png" alt=""></label>
                        <input type="file" id="collection_account_opening_license_photo" value="" @change="imageUpload($event, 9)">
                    </div>
                </div>
                <div class="content_row flex">
                    <label class="info" for="">收款银行卡号<span class="redS">*</span></label>
                    <div class="content_right">
                        <a-input v-model="collection_bank_card_number" size="large" placeholder="请输入收款银行卡号" />
                    </div>
                </div>
                <div class="content_row flex">
                    <label class="info" for="">收款开户总行<span class="redS">*</span></label>
                    <div class="content_right">
                        <a-input v-model="collection_opening_bank" size="large" placeholder="请输入收款开户总行" />
                    </div>
                </div>
                <div class="content_row flex">
                    <label class="info" for="">收款开户支行<span class="redS">*</span></label>
                    <div class="content_right">
                        <a-input v-model="collection_opening_branch" size="large" placeholder="请输入收款开户支行" />
                    </div>
                </div>
                <div class="content_row flex">
                    <label class="info" for="">收款开户名<span class="redS">*</span></label>
                    <div class="content_right">
                        <a-input v-model="collection_account_name" size="large" placeholder="请输入收款开户名" />
                    </div>
                </div>
                <!-- 
                <div class="content_row flex">
                    <label class="info" for="">开通小店登录手机号码<span class="redS">*</span></label>
                    <div class="content_right">
                        <a-input type="number" v-model="open_store_phone" size="large" placeholder="请输入手机号" />
                    </div>
                </div> -->
            </div>
            <div class="save"><a-button type="primary" @click="submit">保存</a-button></div>
        </div>
    </div>
</template>
<script>
// import $ from "jquery";
import { requestXml, isMobile, fileUpload, ajaxUrl } from '../../../assets/js/request';
import Client from '../../../components/client.vue';
export default {
    components: {
        Client
    },
    props: {
        orderId: {
            type: String
        }
    },
    data() {
        return {
            flag: true,
            clientMode: 0,
            url: ajaxUrl,
            // 客户
            // clientList: [],
            client: "",
            client2: "",
            clientFlag: false,
            // 
            // 订单
            contract: 0,
            contractText: "",
            // 日期
            // sign_time: "",
            // date: [],
            start: "",
            end: "",
            // 金额
            money: null,
            // 预收款金额
            amounting: null,
            method: 1,
            methodArr: ["公户打款", "微信", "支付宝", "刷卡", "现金"],
            // 收款时间
            receipt_time: "",
            // 收款备注
            doc: "",

            staffFlag: false,
            staff_uid: "",
            staff_uid2: "",

            // 订单id
            id: "",
            // 状态
            // status: null,
            // orderStatus: ["取消","新建","进行中","已完成"],
            orderField: { value: '', value2: '' },
            detail: "",


            // 类型
            type: [],
            // 进件编号
            osn: "",
            //营业执照名称
            license_name: '',
            //手机号，支付宝
            legal_phone: '',
            legal_zfb: '',
            //商品价格，银行，卡号
            commodity_name: '',
            commodity_price: '',
            bank: '',
            bank_account: '',
            //商户名称
            // tmjcpb_corp__name: '',
            // tmjcpb_corps__zd1719102503:'',
            // tmjcpb_corps__zd1719102509:'',
            //银行账户类型
            bank_account_type: '',
            //开户总行
            opening_bank: '',
            bank_card_number: '',
            //法人身份证号码
            idcard_code: '',
            idcard_name: '',
            //证件生效日期
            idcard_effective_date: '',
            //证件失效日期
            idcard_expiring_date: '',
            //
            idcard_address: '',
            //商户签约名 
            signing_name: '',
            //商户签约类型
            signing_type: '',
            //统一社会信用代码
            unified_credit_code: '',
            //经营范围
            business_scope: '',
            //商户简称
            merchant_abbreviation: '',

            //分佣比例
            commission_sharing_ratio: '',
            //平台服务费
            platform_service_fee: '',
            //所属平台
            platform_belongs: '',
            //
            account_opening_license_photo: '',
            //门店收款账户银行,开户支行,开户预留手机号码
            store_receiving_account_bank: '',
            opening_branch: '',
            //收款银行账户类型
            //收款银行账户类型
            collection_bank_account_type: '',
            //收款银行账户类型
            collection_account_opening_license_photo: '',
            //收款银行卡号
            collection_bank_card_number: '',
            //收款开户总行
            collection_opening_bank: '',
            //收款开户支行
            collection_opening_branch: '',
            //收款开户名
            collection_account_name: '',
            open_store_phone: '',
            opening_phone: '',
            //营业时间,实际经营地址
            business_hours: '',
            //抖音poi
            tiktok_poi: '',
            business_address: '',
            //单张图片
            idcard_front: '',
            idcard_back: '',
            license: '',
            food_license: '',
            qualification_photos: '',
            tiktok_screenshot: '',
            //多张图片
            doorstep_photo: '',
            commodity_photo: [],
            in_store_photo: '',
            options: [
                { label: '团购', value: 1 },
                { label: '外卖', value: 2 },
            ]
        };

    },
    mounted: function () {
        this.clientMode = 1;
        // this.getfield();

        if (isMobile()) {
            this.flag = true;
        } else {
            this.flag = false;
        }

        if (this.$route.query.id) {
            this.id = this.$route.query.id;
            this.getOrderDetail();
        }
        // else{
        //     this.getOsn();
        // }
        // 客户详情页添加时跳转过来的
        this.detail = this.$route.query.detail;

        if (this.$route.query.client) {
            console.log('type', this.$route.query.type)
            this.client = Number(this.$route.query.client);
            this.client2 = this.$route.query.clientName;
            this.type = Number(this.$route.query.type)
        }
    },

    methods: {
        // 选择客户
        chooseClient() {
            this.clientFlag = true;
            this.$refs.sel.blur();
        },
        updateClientFlag(data) {
            for (let i = 0; i < data.length; i++) {
                this.client = data[i].tmjcpb_corp__ids;
                this.client2 = data[i].tmjcpb_corp__name;
            }
            this.clientFlag = false;
        },
        // 选择跟进人
        chooseStaff() {
            this.staffFlag = true;
            this.$refs.sel2.blur();
        },
        updateStaffFlag(data) {
            console.log(data, "dasds")
            if (data != 1) {
                this.staff_uid = data.userid;
                this.staff_uid2 = data.name;
            }
            this.staffFlag = false;
        },
        // 获取进件编号
        // getOsn(){
        //     requestXml("/scrm/order/getOSN","GET",(res) => {
        //         this.osn = res.osn;
        //     })
        // },
        // 
        imageUpload(e, index) {
            fileUpload(e, (res) => {
                console.log(res)
                if (index == 1) {
                    this.idcard_front = res.id;
                }
                if (index == 2) {
                    this.idcard_back = res.id;
                }
                if (index == 3) {
                    this.license = res.id;
                }
                if (index == 4) {
                    this.food_license = res.id;
                }
                if (index == 5) {
                    this.tiktok_screenshot = res.id;
                }
                if (index == 6) {
                    this.food_license = res.id;
                }
                if (index == 7) {
                    this.qualification_photos = res.id;
                }
                if (index == 8) {
                    this.account_opening_license_photo = res.id
                }
                if (index == 9) {
                    this.collection_account_opening_license_photo = res.id

                }
                if (index == 10) {
                    this.in_store_photo=res.id
                }
                if (index == 11){
                    this.doorstep_photo=res.id
                }
            })
        },
        imagesUpload(e, index) {

            fileUpload(e, (res) => {
                if (index == 1) {
                    this.doorstep_photo.push(res.id)
                }
                if (index == 2) {
                    this.commodity_photo.push(res.id)
                }
                if (index == 3) {
                    this.in_store_photo.push(res.id)
                }
            })
        },
        // 订单
        conUpload(e) {
            fileUpload(e, (res) => {
                this.contract = res.id;
                this.contractText = res.name;
            })
        },
        // 获取客户
        // getClientList() {
        //     requestXml("/scrm/Corp/getUnBindCorp","GET",(res) => {
        //         this.clientList = res;
        //     })
        // },
        // 获取订单自定义字段
        getfield() {
            requestXml("/scrm/order/getfield", "GET", (res) => {
                console.log(res)
                this.orderField = res;
            })
        },

        // 获取订单详情
        getOrderDetail() {
            requestXml("/scrm/corp/getPurchaseDetails", "GET", (res) => {

                this.type = res.type;
                this.osn = res.osn;
                this.license_name = res.license_name;
                this.legal_phone = res.legal_phone;
                this.client2 = res.name;
                this.client = res.ids;
                this.legal_zfb = res.legal_zfb;
                this.commodity_name = res.commodity_name;
                this.commodity_price = res.commodity_price;
                this.bank = res.bank;
                this.bank_account = res.bank_account;
                // this.tmjcpb_corp__name = res.tmjcpb_corp__name;
                // this.tmjcpb_corps__zd1719102503 = res.tmjcpb_corps__zd1719102503;
                // this.tmjcpb_corps__zd1719102509 = res.tmjcpb_corps__zd1719102509;
                this.idcard_code = res.idcard_code;
                this.idcard_name = res.idcard_name
                this.idcard_effective_date = res.idcard_effective_date;
                this.idcard_expiring_date = res.idcard_expiring_date;
                this.idcard_address = res.idcard_address;
                this.signing_name = res.signing_name;
                this.signing_type = res.signing_type;
                this.unified_credit_code = res.unified_credit_code;
                this.business_scope = res.business_scope;
                this.merchant_abbreviation = res.merchant_abbreviation;
                this.orderField.value = res.account_allocation_contract;
                this.orderField.value2 = res.contract_name;
                this.commission_sharing_ratio = res.commission_sharing_ratio;
                this.platform_service_fee = res.platform_service_fee;
                this.platform_belongs = res.platform_belongs;
                this.bank_card_number = res.bank_card_number;
                this.bank_account_type = res.bank_account_type;
                this.opening_bank = res.opening_bank;
                this.opening_branch = res.opening_branch;
                this.collection_bank_account_type = res.collection_bank_account_type;
                this.collection_account_opening_license_photo = res.collection_account_opening_license_photo;
                this.collection_bank_card_number = res.collection_bank_card_number;
                this.collection_opening_bank = res.collection_opening_bank;
                this.collection_opening_branch = res.collection_opening_branch;
                this.collection_account_name = res.collection_account_name;
                this.open_store_phone = res.open_store_phone;
                this.opening_phone = res.opening_phone;

                this.tiktok_poi = res.tiktok_poi;
                this.business_hours = res.business_hours;
                this.business_address = res.business_address;
                this.idcard_front = res.idcard_front;
                this.idcard_back = res.idcard_back;
                this.license = res.license;
                this.food_license = res.food_license;
                this.qualification_photos = res.qualification_photos;
                this.account_opening_license_photo = res.account_opening_license_photo;

                this.tiktok_screenshot = res.tiktok_screenshot;
                this.doorstep_photo = res.doorstep_photo;
                this.commodity_photo = res.commodity_photo.split(',');
                this.in_store_photo = res.in_store_photo;

            }, { id: this.id })
        },

        // 附件
        annexUpload(e) {
            fileUpload(e, (res) => {
                this.orderField.value = res.id;
                this.orderField.value2 = res.name;
            })
        },
        // 单张图
        imgUpload(e, index) {
            fileUpload(e, (res) => {
                this.orderField[index].value = res.id;
            })
        },
        // 添加订单
        submit() {
            // if (!this.osn) {
            //     this.$message.warning('请输入进件编号');
            //     return false;
            // }
            if (!this.client) {
                this.$message.warning('请选择要添加的客户');
                return false;
            }
            // if (!this.tmjcpb_corp__name) {
            //     this.$message.warning('请输入商家名称');
            //     return false;
            // }
            // if (!this.tmjcpb_corps__zd1719102503) {
            //     this.$message.warning('请输入联系人');
            //     return false;
            // }
            // if (!this.tmjcpb_corps__zd1719102509) {
            //     this.$message.warning('请输入联系电话');
            //     return false;
            // }

            if (!this.license_name) {
                this.$message.warning('请输入营业执照名称');
                return false;
            }
            if (!this.license) {
                this.$message.warning('请上传营业执照');
                return false;
            }
            if (!this.doorstep_photo) {
                this.$message.warning('请上传门头照');
                return false;
            }
            if (!this.idcard_front) {
                this.$message.warning('请上传法人身份证正面照片');
                return false;
            }
            if (!this.idcard_back) {
                this.$message.warning('请上传法人身份证反面照片');
                return false;
            }
            if (!this.legal_phone) {
                this.$message.warning('请输入法人实名手机号');
                return false;
            }
            if (this.type.indexOf(2) > -1) {
                if (!this.legal_zfb) {
                    this.$message.warning('请输入法人支付宝账号');
                    return false;
                }
            if (!this.food_license) {
                this.$message.warning('请上传食品经营许可证');
                return false;
            }
                if (!this.in_store_photo) {
                    this.$message.warning('请上传室内实景照片');
                    return false;
                }
                if (!this.tiktok_screenshot) {
                    this.$message.warning('请上传抖音来客门店管理中截图');
                    return false;
                }
                if (!this.commodity_name) {
                    this.$message.warning('请输入商品名称');
                    return false;
                }
                if (this.commodity_photo.length < 2) {
                    this.$message.warning('请上传单商品图片');
                    return false;
                }
                if (!this.commodity_price) {
                    this.$message.warning('请输入商品外卖价格');
                    return false;
                }
                if (!this.bank) {
                    this.$message.warning('请输入开户行');
                    return false;
                }
                if (!this.bank_account) {
                    this.$message.warning('请输入结算银行卡卡号');
                    return false;
                }
                if (!this.tiktok_poi) {
                    this.$message.warning('请输入抖音poi');
                    return false;
                }
                if (!this.business_hours) {
                    this.$message.warning('请输入营业时间');
                    return false;
                }
            }
            if (this.type.indexOf(1) > -1) {
                if (!this.idcard_code) {
                    this.$message.warning('请输入法人身份证号码');
                    return false;
                }
                if (!this.idcard_name) {
                    this.$message.warning('请输入法人名称');
                    return false;
                }
                if (!this.idcard_effective_date) {
                    this.$message.warning('请输入证件生效日期');
                    return false;
                }
                if (!this.idcard_expiring_date) {
                    this.$message.warning('请输入证件失效日期');
                    return false;
                }
                if (!this.idcard_address) {
                    this.$message.warning('请输入法人身份证地址');
                    return false;
                }
                if (!this.signing_name) {
                    this.$message.warning('请输入商户签约名');
                    return false;
                }
                if (!this.signing_type) {
                    this.$message.warning('请选择商户签约类型');
                    return false;
                }
                if (!this.unified_credit_code) {
                    this.$message.warning('请输入统一社会信用代码');
                }
                if (!this.business_scope) {
                    this.$message.warning('请输入经营范围');
                    return false;
                }
                if (!this.orderField.value) {
                    this.$message.warning('请上传分账证明合同');
                    return false;
                }
                if (!this.commission_sharing_ratio) {
                    this.$message.warning('请输入分佣比例');
                    return false;
                }
                if (!this.platform_service_fee) {
                    this.$message.warning('请输入平台服务费');
                    return false;
                }
                if (!this.merchant_abbreviation) {
                    this.$message.warning('请输入商户简称');
                    return false;
                }
                if (!this.platform_belongs) {
                    this.$message.warning('请输入平台服务费');
                    return false;
                }
                if (!this.qualification_photos) {
                    this.$message.warning('请上传资质照片');
                    return false;
                }
                
                if (!this.bank_account_type) {
                    this.$message.warning('请选择银行账户类型');
                    return false;
                }
                if (!this.account_opening_license_photo) {
                    this.$message.warning('请上传开户许可照片');
                    return false;
                }
                if (!this.bank_card_number) {
                    this.$message.warning('请输入银行卡号');
                    return false;
                }
                if (!this.opening_bank) {
                    this.$message.warning('请输入开户总行');
                    return false;
                }
                if (!this.opening_branch) {
                    this.$message.warning('请输入开户支行');
                    return false;
                }
                if (!this.collection_bank_account_type) {
                    this.$message.warning('请选择收款银行账户类型');
                    return false;
                }
                if (!this.collection_account_opening_license_photo) {
                    this.$message.warning('请上传收款开户许可照片');
                    return false;
                }
                if (!this.collection_bank_card_number) {
                    this.$message.warning('请输入收款银行卡号');
                    return false;
                }
                if (!this.collection_opening_bank) {
                    this.$message.warning('请输入收款开户总行');
                    return false;
                }
                if (!this.collection_opening_branch) {
                    this.$message.warning('请输入收款开户支行');
                    return false;
                }
                if (!this.collection_account_name) {
                    this.$message.warning('请输入收款开户名');
                    return false;
                }
                // if (!this.opening_phone) {
                //     this.$message.warning('请输入开户预留手机号码');
                //     return false;
                // }
                // if (!this.open_store_phone) {
                //     this.$message.warning('请输入开通小店登录手机号码');
                //     return false;
                // }
                if (!this.business_address) {
                    this.$message.warning('请输入经营地址');
                    return false;
                }
            }

            // 添加 更新
            var url;
            if (this.id) {
                url = "/scrm/corp/upPurchase"
            } else {
                url = "/scrm/corp/addPurchase"
            }

            
            let orderData = {
                "id": this.id,
                "ids": this.client,
                "type": this.type,
                "osn": this.osn,
                "license_name": this.license_name,
                "license": this.license,
                "legal_phone": this.legal_phone,
                "legal_zfb": this.legal_zfb,
                "commodity_name": this.commodity_name,
                "commodity_price": this.commodity_price,
                "bank": this.bank,
                "bank_account": this.bank_account,
                // "tmjcpb_corp__name":this.tmjcpb_corp__name,
                // "tmjcpb_corps__zd1719102503":this.tmjcpb_corps__zd1719102503,
                // "tmjcpb_corps__zd1719102509":this.tmjcpb_corps__zd1719102509,

                "idcard_code": this.idcard_code,
                "idcard_name": this.idcard_name,
                "idcard_effective_date": this.idcard_effective_date,
                "idcard_expiring_date": this.idcard_expiring_date,
                "idcard_address": this.idcard_address,
                "signing_name": this.signing_name,
                "signing_type": this.signing_type,
                "unified_credit_code": this.unified_credit_code,
                "business_scope": this.business_scope,
                "merchant_abbreviation": this.merchant_abbreviation,
                "account_allocation_contract": this.orderField.value,
                "commission_sharing_ratio": this.commission_sharing_ratio,
                "platform_service_fee": this.platform_service_fee,

                "platform_belongs": this.platform_belongs,
                "bank_card_number": this.bank_card_number,
                "bank_account_type": this.bank_account_type,
                "opening_bank": this.opening_bank,
                "opening_branch": this.opening_branch,
                "collection_bank_account_type": this.collection_bank_account_type,
                "collection_account_opening_license_photo": this.collection_account_opening_license_photo,
                "collection_bank_card_number": this.collection_bank_card_number,
                "collection_opening_bank": this.collection_opening_bank,
                "collection_opening_branch": this.collection_opening_branch,
                "collection_account_name": this.collection_account_name,
                "open_store_phone": this.open_store_phone,
                "opening_phone": this.opening_phone,
                "business_hours": this.business_hours,
                "tiktok_poi": this.tiktok_poi,
                "business_address": this.business_address,
                "idcard_front": this.idcard_front,
                "idcard_back": this.idcard_back,
                "food_license": this.food_license,
                "tiktok_screenshot": this.tiktok_screenshot,
                "qualification_photos": this.qualification_photos,
                "account_opening_license_photo": this.account_opening_license_photo,
                "doorstep_photo": this.doorstep_photo,
                "commodity_photo": this.commodity_photo.join(','),
                "in_store_photo": this.in_store_photo
            }
            requestXml(url, "POST", (res) => {
                if (res == "success") {
                    this.$router.push({
                        path: '/scrm_pc/client/purchase',
                    });
                }
            }, orderData)
        }
    }
};
</script>
<style scoped>
.content_right img {
    width: 120px;
}

.content-box {
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: left .3s ease-in-out;
    overflow-y: auto;
}

.content {
    width: auto;
    margin: 0;
    padding: 0;
    background: #fff;
}

.content .content_row {
    align-items: center;
    margin: 2rem 0;
}

.content_right {
    width: calc(100% - 120px);
    text-align: left;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #36A3FF;
    border-color: #36A3FF;
}

.content .content_row .info {
    display: inline-block;
    width: 15rem;
    text-align: right;
    padding-right: 1rem;
    color: #202020;
    font-weight: normal;
}

/* .content .content_row .fileLabel {
    line-height: 1.499;
    font-weight: 400;
    background-image: none;
    border: 1px solid #d9d9d9;
    box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
    cursor: pointer;
    height: 32px;
    line-height: 32px;
    padding: 5px 15px;
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.65);
} */

.content .content_row input[type="file"] {
    display: none;
}

/* 输入框 下拉框 */
.content .content_row .ant-input,
.content .content_row .ant-select {
    width: 100%;
    /* background-color: #F9F9F9; */
}

.content .content_row .ant-select-selection--multiple input {
    width: 0;
}

/* 日期 */
.content .content_row .ant-calendar-picker {
    width: 100% !important;
}

.content .content_row .ant-calendar-picker input {
    width: 100%;
}

.save {
    text-align: left;
}

.save .ant-btn {
    width: 100px;
    height: 40px;
    background: #36A3FF;
    border-radius: 4px;
    margin-left: 13rem;
    position: inherit;
}

@media screen and (min-width: 750px) {
    .content-box {
        position: absolute;
        left: 240px;
        right: 0;
        top: 60px;
        bottom: 0;
        transition: left .3s ease-in-out;
        height: calc(100% - 60px);
        overflow-y: scroll;
    }

    img {
        max-width: 300px;
    }

    .content {
        width: calc(100vw - 300px);
        margin: 20px;
        padding: 20px;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0px 0px 10px 0px rgba(235, 236, 238, 0.8);
    }

    .content .content_row {
        margin: 15px 0;
        font-size: 16px;
    }

    .content .content_row .info {
        width: 200px;
        padding-right: 10px;
    }

    .content .content_row .ant-calendar-picker {
        width: 400px !important;
    }

    .content .content_row .ant-calendar-picker input {
        width: 100% !important;
    }

    /* input ,selsct */
    .content .content_row .ant-input,
    .content .content_row .ant-input-affix-wrapper,
    .content .content_row .ant-select {
        width: 400px;
    }

    .save .ant-btn {
        margin-left: 105px;
    }

}
</style>
